// import React, { useState,useEffect } from "react";
// import { Button, Grid } from "@mui/material";
// import axios from "axios";
// import { deleteDoc, doc } from "firebase/firestore";
// import { useNavigate } from "react-router-dom";
// import { database } from "../../Firebase/Firebase";
// import { useAuthContext } from "../../Providers/AuthProvider";
// import { useDraftAssignmentContext } from "../../Providers/DraftAssignmentProvider";
// import { useFormBuilderContext } from "../../Providers/FormBuilderProvider";
// import { useToastProvider } from "../../Providers/ToastProvider";
// import { ArrowRightAltOutlined } from "@mui/icons-material";

// const FormItem = (props) => {
//   const navigate = useNavigate();
//   const { setForm } = useDraftAssignmentContext();
//   const { user } = useAuthContext();
//   const { dispatch, setMode } = useFormBuilderContext();
//   const { showSuccess, showError } = useToastProvider();

//   const fieldKey = `idCounter_${props.id}`;
  
//   // Retrieve the stored idCounter value from localStorage or generate a new one
//   const [idCounter, setIdCounter] = useState(() => {
//     const storedIdCounter = localStorage.getItem(fieldKey);
//     if (storedIdCounter) {
//       return parseInt(storedIdCounter);
//     } else {
//       const randomId = Math.floor(Math.random() * 10000);
//       localStorage.setItem(fieldKey, randomId.toString());
//       return randomId;
//     }
//   });

//   const deleteForm = async () => {
//     await deleteDoc(
//       doc(database, "agency/" + user.uid, "forms/" + props.id)
//     ).then(async (res) => {
//       showSuccess("Successfully Deleted Form " + props.id);
//       console.log(res);
//     })
//       .catch((err) => {
//         showError();
//       });
//   };

//   return (
//     <div>
//       <div
//         style={{
//           height: "47px",
//           width: "100%",
//           display: "inline-flex",
//           alignItems: "center",
//           fontSize: "14px",
//           color: "gray",
//           fontFamily: "Source Serif Pro, serif",
//           justifyContent: "center",
//         }}
//       >
//         <Grid container>
//           <Grid
//             item
//             xs={4}
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             {idCounter}
//           </Grid>
//           <Grid
//             item
//             xs={4}
//             sx={{
//               display: "flex",
//               justifyContent: "start",
//               alignItems: "center",
//             }}
//           >
//             {props.name}
//           </Grid>
//           <Grid
//             item
//             xs={4}
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             {props.mode === "select" ? (
//               <Button
//                 size="small"
//                 onClick={() => {
//                   setForm(props.form);
//                   props.setOpen(false);
//                 }}
//                 variant="outlined"
//                 sx={{
//                   color: "white",
//                   bgcolor: "#1260cc",
//                   borderRadius: "17px",
//                   fontSize: "10px",
//                   fontWeight: "bold",
//                   padding: "0.5em 1.3em 0.5em 0.8em",
//                   border: "1.3px solid #1260cc",
//                   "&:disabled": {
//                     bgcolor: "#eaeaea",
//                     border: "1.3px solid #eaeaea",
//                     color: "#aeaeae",
//                   },
//                   "&:hover": {
//                     border: "1.3px solid #1260cc",
//                     color: "white",
//                     bgcolor: "#5D89C7",
//                   },
//                 }}
//               >
//                 <ArrowRightAltOutlined
//                   fontSize="small"
//                   sx={{
//                     transform: "scale(0.8)",
//                     marginRight: "0.2em",
//                   }}
//                 />
//                 Choose
//               </Button>
//             ) : (
//               <div style={{ display: "inline" }}>
//                 <Button
//                   variant="contained"
//                   size="small"
//                   sx={{
//                     display: "inline",
//                     margin: "0 0.4em",
//                   }}
//                   onClick={() => {
//                     setMode("edit");
//                     dispatch({ type: "loadForm", payload: props.form });
//                     navigate("/dashboard/formBuilderPage", {
//                       state: { mode: "edit" },
//                     });
//                   }}
//                 >
//                   Edit
//                 </Button>
//                 <Button
//                   variant="contained"
//                   onClick={() => {
//                     deleteForm();
//                   }}
//                   size="small"
//                   sx={{
//                     margin: "0 0.4em",
//                     backgroundColor: "red",
//                     display: "inline",
//                     "&:hover": {
//                       backgroundColor: "darkred",
//                     },
//                   }}
//                 >
//                   Delete
//                 </Button>
//               </div>
//             )}
//           </Grid>
//         </Grid>
//       </div>
//       <hr
//         style={{
//           width: "95%",
//           margin: "0 auto",
//           border: "0.6px solid #dedede",
//         }}
//       />
//     </div>
//   );
// };

// export default FormItem;


import { Delete, Edit, ArrowRightAltOutlined, Search } from "@mui/icons-material";
import { deleteDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { database } from "../../Firebase/Firebase";
import { useAuthContext } from "../../Providers/AuthProvider";
import { useDraftAssignmentContext } from "../../Providers/DraftAssignmentProvider";
import { useFormBuilderContext } from "../../Providers/FormBuilderProvider";
import { useToastProvider } from "../../Providers/ToastProvider";
import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';


const FormItem = (props) => {
  const navigate = useNavigate();
  const { setForm } = useDraftAssignmentContext();
  const { user } = useAuthContext();
  const { dispatch, setMode } = useFormBuilderContext();
  const { showSuccess, showError } = useToastProvider();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [formId, setFormId] = useState('');

  

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const deleteForm = async () => {
    handleCloseDeleteDialog(); // Close the dialog
    await deleteDoc(
      doc(database, "agency/" + user.uid, "forms/" + props.id)
    )
      .then(async (res) => {
        showSuccess("Successfully Deleted Form " + props.id);
        console.log(res);
      })
      .catch((err) => {
        showError();
      });
  };

  const fieldKey = `idCounter_${props.id}`;
  
  const [idCounter, setIdCounter] = useState(() => {
    const storedIdCounter = localStorage.getItem(fieldKey);
    if (storedIdCounter) {
      return parseInt(storedIdCounter);
    } else {
      const randomId = Math.floor(Math.random() * 10000);
      localStorage.setItem(fieldKey, randomId.toString());
      return randomId;
    }
  });

  return (
    <div>
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this form?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={deleteForm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
  
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{display:'flex',justifyContent:'center',textAlign:'center'}}>
              <TableCell style={{ width: '33%' ,display:'flex',justifyContent:'center',textAlign:'center'}}>  {idCounter}</TableCell>
              <TableCell style={{ width: '33%',display:'flex',justifyContent:'center',textAlign:'center' }}>{props.name}</TableCell>
              <TableCell style={{ width: '33%',display:'flex',justifyContent:'center',textAlign:'center' }}>
                {props.mode === "select" ? (
                  <Button
                    size="small"
                    onClick={() => {
                      setForm(props.form);
                      props.setOpen(false);
                    }}
                    variant="outlined"
                    sx={{
                      color: "white",
                      bgcolor: "#1260cc",
                      borderRadius: "17px",
                      fontSize: "10px",
                      fontWeight: "bold",
                      padding: "0.5em 1.3em 0.5em 0.8em",
                      border: "1.3px solid #1260cc",
                      "&:disabled": {
                        bgcolor: "#eaeaea",
                        border: "1.3px solid #eaeaea",
                        color: "#aeaeae",
                      },
                      "&:hover": {
                        border: "1.3px solid #1260cc",
                        color: "white",
                        bgcolor: "#5D89C7",
                      },
                    }}
                  >
                    <ArrowRightAltOutlined
                      fontSize="small"
                      sx={{
                        transform: "scale(0.8)",
                        marginRight: "0.2em",
                      }}
                    />
                    Choose
                  </Button>
                ) : (
                  <div style={{ display: "flex",gap:'30px' }}>
                    <Tooltip title="Edit" arrow>
                      <IconButton
                        onClick={() => {
                          setMode("edit");
                          dispatch({ type: "loadForm", payload: props.form });
                          navigate("/dashboard/formBuilderPage", {
                            state: { mode: "edit" },
                          });
                        }}
                        style={{borderRadius:'7px',border:'1px solid gray', display:'flex',justifyContent:'center',textAlign:'center',gap:'3px'}}
                      >
                        <Edit style={{width:'25px',height:'18px'}}/><p style={{fontSize:'13px'}}>Edit</p>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" arrow>
                      <IconButton
                        onClick={handleOpenDeleteDialog} // Open delete confirmation dialog
                        style={{borderRadius:'7px',border:'1px solid gray',background:'#4270b7',display:'flex',justifyContent:'center',textAlign:'center',gap:'2px'}}
                      >
                     <Delete style={{ color: "white",width:'25px',height:'18px' }} /><p style={{fontSize:'13px',color:'white'}}>Delete</p>
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default FormItem;

