export const privacyText = {
  paragraph1:
    "VeriDocs | Automation of verification process, claim process, settlement process, field work automation, online field verification, background verification.",
  paragraph2:
    "Automotive Field Verification, Efficient Auditing & Reporting: Improving collaboration between Field Executives & Agencies to increase the productivity in field verification, claim, settlement and background verification processes.",
};
export const privacyText2 = {
  paragraph3:
    "VeriDocs is a powerful smartly designed and developed solution to manage the organization’s customers document verification process. It helps the companies to manage their customer’s Field verification process along with their field verifier/representatives efficiently.",
  paragraph4:
    "It is suitable for the companies who are involved in Field verification process in any industries like Banking, Finance, and Insurance. It helps the company to automate the process of the document’s assignment to a field verifier their visits to end customer and respective process involved.",
  paragraph5:
    "Why VeriDocs? Physical 2 Digital: Capture of information in a digital format and no paperwork for field executives with Digital smart forms, transforms the Physical work to Digital.",
  paragraph6:
    "Secure & Cloud-based: Cloud-based infrastructure provides End-to-End encryption and increases your data security.",
  paragraph7:
    "Customizable: VeriDocs is almost 100% customizable as per your need with simple and easy to use UI which provides seamless user experience.",
  paragraph8:
    "Improved Decision Making: With real time data and reduced verification errors, you can make efficient & Improved decision making",
  paragraph9:
    "Field & Background verification is an important aspect of many industries to check the authenticity of a person or a company. Inefficient management of these processes leads to a drop in productivity and therefore profitability.  VeriDocs helps to increase Productivity & Profitibility.",
};
export const featuresText = {
  features1:
    "VeriDocs is a bundle of multiple features, suitable for Banking, Financial and Insurance sectors. Some of its features are:",
  features2:
    "Easy Collaboration: Effective collaboration between Field Representatives and Back-Office makes approval process easy and fast.",

  features3:
    "Simple Task View: VeriDocs provides your field representatives a simple view of assigned tasks and help them plan their works more efficiently. They can also filter by assignments types to see what they need to work at what time.",
  features4:
    "Automated Workflow: Automated Workflows makes most of field representatives and agency works easy, and hence auditing process is easy and takes less time.",
  features5:
    "We offer comparatively the best Price that you can get from others.",
  features6:
    "Real-Time Insights: You can get access to most of the information and reports from the field representatives in one place with VeriDocs application.",
};
export const policylist = {
  policylist1: "Through its online service using VeriDocs  mobile & web apps.",
  policylist2: "Through physical verification of addresses and locations.",
  policylist3:
    "Through the collection of various physical documents from individuals, through APIs or other technological or software systems or methods that gather or receive data from its clients’ servers ",
  policylist4:
    "Through communication and correspondence with various corporate bodies. ",
};

export const policylist2 = {
  policylist1:
    "How and why we collect, store, and use your personal data in the various capacities in which you interact with us",
  policylist2:
    "The rights that you have to determine the outlines of this interaction.",
};
export const information = {
  information1:
    "Company: The company name, mobile number and e-mail that is used during signs up to avail our services",
  information2:
    "Organization/Company: We also collect company billing address for billing purpose.",
  information3:
    "User/Representatives: We collect user’s name, email, PAN and Aadhaar for user authentication and validation purposes.",
  information4:
    "User/Representative: Camera and photo gallery to capture images and recordings for the purpose of customer information collection and updating user images along with Geo-location within the application, if you have opted for the same in the relevant section of the App.",
  information5:
    "OTP SMS’s that you receive on your Device from our solutions for authentication and login ",
  information6:
    "Third-party Individuals: Since our solution involves documents verification of third-party individuals, we collect their personal information such as name, address, email, mobile, location, etc. We collect this data in order to facilitate the provision of our Services. These are main purposes of this application.",
};
export const retention = {
  information1:
    "We will store any personal data we collect from you as long as it is necessary in order to  use our Services and essential business purposes – these include, without limitation, improving our Services, attending to technical issues, and dealing with disputes.",
  information2:
    "We may need to retain your personal data even if you seek deletion thereof if it is needed to comply with our legal obligations, resolve disputes and enforce our agreements.",
};

export const thirdparty = {
  information1:
    "We may display links to third-party websites or applications on our Website or App for advertising or providing you with relevant content. We will not be responsible for such third-party websites or applications if you choose to access them. If you provide any data to such website/application, please ensure you read their policies given that you will no longer be bound by this Policy in doing so.",
};
export const security = {
  information1:
    "We implement industry-standard technical and organizational measures by using a variety of security technologies and procedures to help protect your data from unauthorized access, use, loss, destruction, or disclosure. Your data is encrypted using industry-standard cryptographic techniques including but not limited to SSL.",
};
export const officer = {
  information1:
    "If you have any concerns, complaints, or feedback pertaining to this Policy, you may write to our Grievance Officer, whose details are provided below. VeriDocs is committed to resolving any such grievances in an expeditious and effective manner.",
};
export const details = {
  information1: "Name: Priyanka Garg",
  information2: "Email: priyanka.garg@reignsys.com",
  information3: "Mobile: +919686473714",
};
