import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Button, Grid, Paper, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { collection, doc, DocumentData, getDoc, onSnapshot, query, where } from "firebase/firestore";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { database } from "../../Firebase/Firebase";
import { useAuthContext } from "../../Providers/AuthProvider";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";

const VerifyAssignmentsPage: React.FunctionComponent = () => {
    const { user } = useAuthContext();
    const [mounted, setMounted] = useState(false);
    const [submittedAss, setSubmittedAss] = useState<DocumentData[]>([]);
    const [selectedFormName, setSelectedFormName] = useState<string>("");
    const [selectedVerifierName, setSelectedVerifierName] = useState<string>("");
    const navigate = useNavigate();

    useEffect(() => {
        setMounted(true);
        return () => {
            setMounted(false);
        };
    }, []);

    useEffect(() => {
        let unsubscribe = () => { };
        if (user !== null && user.uid !== undefined) {
            let q = query(collection(database, 'assignments'),
                where('agency', '==', user.uid),
                where('status', '==', 'submitted')
            );

            unsubscribe = onSnapshot(q, snapshot => {
                let ass: Array<DocumentData> = [];
                snapshot.docs.forEach((doc) => {
                    ass.push({ ...doc.data(), id: doc.id });
                });
                setSubmittedAss(ass);
            });
        }
        return () => {
            return unsubscribe();
        };
    }, [mounted, user]);

    const handleFormNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedFormName(event.target.value as string);
    };

    const handleVerifierNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedVerifierName(event.target.value as string);
    };

 
const filteredAssignments = submittedAss.filter(assignment => {
    if (selectedFormName === "all" || selectedVerifierName === "all") {
        return true; 
    }

    const matchesFormName = selectedFormName === "" || assignment.formName === selectedFormName;
    const matchesVerifierName = selectedVerifierName === "" || assignment.assigned_name === selectedVerifierName;
    return matchesFormName && matchesVerifierName;
});

    const formNames = Array.from(new Set(submittedAss.map(assignment => assignment.formName)));
    const verifierNames = Array.from(new Set(submittedAss.map(assignment => assignment.assigned_name)));

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'end', textAlign: 'center', alignItems: 'center', gap: '20px' }}>
                <p>Filter By:</p>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel id="form-name-label" sx={{ marginTop: '-7px' }}>Select Form</InputLabel>
                    <Select
                        labelId="form-name-label"
                        value={selectedFormName}
                        onChange={handleFormNameChange}
                        label="Form Name"
                        sx={{ height: 35 }}
                    >
                        <MenuItem value="all">
                            <em>All</em>
                        </MenuItem>
                        {formNames.map((formName, index) => (
                            <MenuItem key={index} value={formName}>
                                {formName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel id="verifier-name-label" sx={{ marginTop: '-7px' }}>Select Verifier</InputLabel>
                    <Select
                        labelId="verifier-name-label"
                        value={selectedVerifierName}
                        onChange={handleVerifierNameChange}
                        label="Verifier Name"
                        sx={{ height: 35 }}
                    >
                        <MenuItem value="all">
                            <em>All</em>
                        </MenuItem>
                        {verifierNames.map((verifierName, index) => (
                            <MenuItem key={index} value={verifierName}>
                                {verifierName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: '700' }}>ID</TableCell>
                            <TableCell sx={{ fontWeight: '700' }}>Form Name</TableCell>
                            <TableCell sx={{ fontWeight: '700' }}>Verifier Name</TableCell>
                            <TableCell sx={{ fontWeight: '700' }}>Action</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {filteredAssignments.map((assignment) => (
                            <TableRow key={assignment.id}>
                                <TableCell>{assignment.id}</TableCell>
                                <TableCell>{assignment.formName}</TableCell>
                                <TableCell>{assignment.assigned_name}</TableCell>
                                <TableCell>
                                    <Button size='small' variant="contained" onClick={() => {
                                        navigate('/dashboard/verify/result', {
                                            state: {
                                                id: assignment.id,
                                                fv: assignment.assigned_to,
                                                fvId: assignment.fvId,
                                                docType: assignment.document_type
                                            }
                                        });
                                    }}>
                                        <CheckCircleOutlineIcon sx={{ width: '30px', marginLeft: '3px' }} /> Verify
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};




interface FvProps {
    uid: string;
}

const FvName: React.FunctionComponent<FvProps> = (props): ReactJSXElement => {
    const [name, setName] = useState<String>();
    const getFvName = async () => {
        const snapshot = await getDoc(doc(database, "field_verifier", props.uid));
        let data: DocumentData | undefined = snapshot.data();
        setName(data?.name);
    }
    useEffect(() => {
        getFvName();
    });
    return (<div>
        {name}
    </div>)
}

export default VerifyAssignmentsPage;
