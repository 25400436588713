// import { Add } from "@mui/icons-material";
// import { Grid, IconButton, Tooltip } from "@mui/material";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useFormBuilderContext } from "../../Providers/FormBuilderProvider";
// import { useFormsContext } from "../../Providers/FormsProvider";
// import FormItem from "./FormItem";

// const Forms = () => {
//     const {forms} = useFormsContext();

//     const navigate = useNavigate();
//     const {state } = useLocation();
//     const {setFormId} = useFormBuilderContext();
    
//     const {mode} = state;

//     return (<div>
//       <div style={{
//             height: '30px',
//             fontWeight: 'bold',
//             fontSize: '12px',
//             width: '100%',
//             display: 'inline-flex',
//             cursor: 'pointer',
//             alignItems: 'center',
//             justifyContent: 'center',
//         }}>
//             <Grid container >
//                 <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center', color: '#404040'}}>
//                     Form ID
//                 </Grid>
//                 <Grid item xs={4} sx={{display: 'flex', justifyContent: 'start', color: '#404040'}}>
//                     Form Name
//                 </Grid>
//                 <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center', color: '#404040'}}>
//                     Action
//                 </Grid>
//             </Grid>
//         </div>
//         <hr style={{width: '93%', margin: '0 auto', border: '0.6px solid #dedede'}}/>
//       {forms.map((form, index) => (
//       <FormItem key={index} id={form.id} name={form.name} form={form} mode={mode}/>))}
//       {/* <Tooltip title="Create new Form" arrow>
//         <IconButton sx={{
//           border: '1px solid grey',
//           margin: '0.3em'
//         }} onClick={() => {
//           setFormId(null);
//           navigate('/dashboard/chooseTemplate', {state: {mode: mode}});
//         }}>
//           <Add />
//         </IconButton>
//       </Tooltip>
//        */}
//     </div>);
// }


// export default Forms;




import React, { useState } from "react";
import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip ,InputBase,Paper} from "@mui/material";
import { Add ,Search} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormBuilderContext } from "../../Providers/FormBuilderProvider";
import { useFormsContext } from "../../Providers/FormsProvider";
import FormItem from "./FormItem";

const Forms = () => {
    const { forms } = useFormsContext();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { setFormId } = useFormBuilderContext();
    const { mode } = state;

    const [searchQuery, setSearchQuery] = useState("");

    // Filter forms based on search query
    const filteredForms = forms.filter(form =>
        form.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <div style={{display:'flex',justifyContent:'flex-end', paddingTop:'20px',paddingBottom:'35px'}}>
                {/* <Tooltip title="Create new Form" arrow>
                    <IconButton
                        style={{border:'1px solid gray',borderRadius:'6px',marginBottom:'10px'}}
                        onClick={() => {
                            setFormId(null);
                            navigate('/dashboard/chooseTemplate', { state: { mode: mode } });
                        }}>
                        <Add /> <p style={{fontSize:'13px'}}>Create New Form</p> 
                    </IconButton>
                </Tooltip> */}
                <Paper style={{ padding: '2px 9px', display: 'flex', justifyContent:'space-between', width: 300, marginBottom: '10px' ,height:'40px'}}>
                    <InputBase
                        placeholder="Search Forms By Names"
                        inputProps={{ 'aria-label': 'search forms' }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <IconButton type="submit" aria-label="search">
                        <Search />
                    </IconButton>
                </Paper>
            </div>
            <div style={{
                height: '30px',
                fontWeight: 600,
                fontSize: '20px',
                width: '100%',
                display: 'inline-flex',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '33%', textAlign: 'center', color: '#404040',fontSize:'15px',fontWeight:'600',fontFamily:'sans-serif' }}>Form ID</TableCell>
                                <TableCell style={{ width: '33%', textAlign: 'center', color: '#404040' ,fontSize:'15px',fontWeight:'600',fontFamily:'sans-serif'}}>Form Name</TableCell>
                                <TableCell style={{ width: '33%', textAlign: 'center', color: '#404040',fontSize:'15px',fontWeight:'600',fontFamily:'sans-serif' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            </div>
            <TableContainer>
                <Table>
                    <TableBody>
                        {filteredForms.map((form, index) => (
                            <FormItem key={index} id={form.id} name={form.name} form={form} mode={mode} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default Forms;

