import { Button, Grid, Paper } from "@mui/material";
import { collection, doc, getDoc, onSnapshot, orderBy, query, where, deleteDoc, writeBatch } from "firebase/firestore";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { database } from "../../Firebase/Firebase";
import { useAuthContext } from "../../Providers/AuthProvider";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    documentTypes,
    useDraftAssignmentContext,
} from "../../Providers/DraftAssignmentProvider";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useToastProvider } from "../../Providers/ToastProvider";
import { useFormsContext } from "../../Providers/FormsProvider";
import { useFieldVerifiersContext } from "../../Providers/FieldVerifiersProvider";

let status1 = '';
let formName = ''; 

const AssignmentsPage = () => {
    const { showError, showSuccess } = useToastProvider();
    const navigate = useNavigate();
    const [assignments, setAssignments] = useState([]);
    const { user } = useAuthContext();
    const { forms } = useFormsContext();
    const { fvs } = useFieldVerifiersContext();
    let unsubscribe = () => { };


    const [open, setOpen] = useState(false);
    const [assignmentIdDelete, setAssignmentDelete] = useState();
    const [selectedForm, setSelectedForm] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedName, setSelectedName] = useState('');
    const [uniqueNames, setUniqueNames] = useState([]);

    const handleClickOpen = (assign) => {
        setAssignmentDelete(assign);
        setOpen(true);
    };

    const handleClose = (response) => {
        if (response === 'yes') {
            deleteAssignment();
        }
        setOpen(false);
    };

    const deleteAssignment = async () => {
        try {
            const batch = writeBatch(database);
            const ref1 = doc(database, "assignments", assignmentIdDelete.id);
            batch.delete(ref1);
            const ref2 = doc(database, `agency/${assignmentIdDelete.agency}/assignments`, assignmentIdDelete.id);
            batch.delete(ref2);
            const ref3 = doc(database, `field_verifier/${assignmentIdDelete.assigned_to}/assignments`, assignmentIdDelete.id);
            batch.delete(ref3);

            await batch.commit();

            showSuccess("Assignment deleted");
        } catch (e) {
            showError("Failed to delete");
        }
    };

    const handleChange = (e) => {
        setSelectedStatus(e.target.value);
    };

    const handleTypeChange = (e) => {
        setSelectedForm(e.target.value);
    };

    const handleNameChange = (e) => {
        setSelectedName(e.target.value);
        // Call getAssignments again to apply the new filter
        getAssignments();
    };
    

    const convert = (timestamp) => {
        if (typeof timestamp === "string") {
            return timestamp;
        } else {
            return timestamp.toDate().toDateString() + " " + timestamp.toDate().toLocaleTimeString('en-US');
        }
    };

    const getAssignments = () => {
        let q;
        if (selectedForm === 'all' || selectedStatus === 'all' || selectedName === 'all') {
            q = query(collection(database, 'assignments'),
                where('agency', '==', user.uid),
                orderBy('assigned_at', 'desc'));
        } else if (selectedForm && selectedStatus && selectedName) {
            q = query(collection(database, 'assignments'),
                where('agency', '==', user.uid),
                where('formName', '==', selectedForm),
                where('status', '==', selectedStatus),
                where('assigned_name', '==', selectedName),
                orderBy('assigned_at', 'desc'));
        } else if (selectedForm && selectedStatus) {
            q = query(collection(database, 'assignments'),
                where('agency', '==', user.uid),
                where('formName', '==', selectedForm),
                where('status', '==', selectedStatus),
                orderBy('assigned_at', 'desc'));
        } else if (selectedForm && selectedName) {
            q = query(collection(database, 'assignments'),
                where('agency', '==', user.uid),
                where('formName', '==', selectedForm),
                where('assigned_name', '==', selectedName),
                orderBy('assigned_at', 'desc'));
        } else if (selectedStatus && selectedName) {
            q = query(collection(database, 'assignments'),
                where('agency', '==', user.uid),
                where('status', '==', selectedStatus),
                where('assigned_name', '==', selectedName),
                orderBy('assigned_at', 'desc'));
        } else if (selectedForm) {
            q = query(collection(database, 'assignments'),
                where('agency', '==', user.uid),
                where('formName', '==', selectedForm),
                orderBy('assigned_at', 'desc'));
        } else if (selectedStatus) {
            q = query(collection(database, 'assignments'),
                where('agency', '==', user.uid),
                where('status', '==', selectedStatus),
                orderBy('assigned_at', 'desc'));
        } else if (selectedName) {
            q = query(collection(database, 'assignments'),
                where('agency', '==', user.uid),
                where('assigned_name', '==', selectedName),
                orderBy('assigned_at', 'desc'));
        } else {
            q = query(collection(database, 'assignments'),
                where('agency', '==', user.uid),
                orderBy('assigned_at', 'desc'));
        }
    
        if (user && user.uid !== undefined) {
            setAssignments([]);
            unsubscribe = onSnapshot(q, snapshot => {
                let data = [];
                let namesSet = new Set(); // Using Set to collect unique names
                snapshot.docs.forEach((doc) => {
                    data.push({ ...doc.data(), id: doc.id });
                    namesSet.add(doc.data().assigned_name); // Assuming assigned_name is the field containing names
                });
                setAssignments(data);
                // Always update uniqueNames with all names from the snapshot
                let allNames = snapshot.docs.map(doc => doc.data().assigned_name);
                setUniqueNames(Array.from(new Set(allNames))); // Convert Set to array and update state
            });
        }
    };
    


    useEffect(() => {
        const unsubscribe = getAssignments();
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [user, selectedForm, selectedStatus, selectedName]);

    return (
        <div>
            <Paper elevation={0}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 10px 20px 10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        Filter By:
                    </div>
                    <FormControl sx={{ ml: 1, minWidth: 160 }} size="small">
                        <InputLabel id="form-label">Select Form</InputLabel>
                        <Select
                            labelId="form-label"
                            id="form-select"
                            value={selectedForm}
                            onChange={handleTypeChange}
                            label="Select Form"
                        >
                            <MenuItem value={'all'}>All Forms</MenuItem>
                            {forms.map((form, index) => (
                                <MenuItem key={index} value={form.name}>
                                    {form.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ ml: 1, minWidth: 160 }} size="small">
                        <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedStatus}
                            onChange={handleChange}
                            label="Select Status"
                        >
                            <MenuItem value={'all'}>All</MenuItem>
                            <MenuItem value={'assigned'}>Assigned</MenuItem>
                            <MenuItem value={'in_progress'}>In Progress</MenuItem>
                            <MenuItem value={'submitted'}>Submitted</MenuItem>
                            <MenuItem value={'approved'}>Approved</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ ml: 1, minWidth: 160 }} size="small">
                        <InputLabel id="form-label">Select Name</InputLabel>
                        <Select
                           labelId="form-label"
                            id="form-select"
                            value={selectedName}
                            onChange={handleNameChange}
                            label="Select Name"
                        >
                            <MenuItem value={'all'}>All</MenuItem>
                            {uniqueNames.map((name, index) => (
                                <MenuItem key={index} value={name}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </Paper>
            <TableContainer component={Paper} elevation={3} variant="outlined">
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: "800" }}>ID</TableCell>
                            <TableCell align="center" style={{ fontWeight: "800" }}>Form Name</TableCell>
                            <TableCell align="center" style={{ fontWeight: "800" }}>Name</TableCell>
                            <TableCell align="center" style={{ fontWeight: "800" }}>Status</TableCell>
                            <TableCell align="center" style={{ fontWeight: "800" }}>Assigned at</TableCell>
                            <TableCell align="center" style={{ fontWeight: "800" }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assignments.map((assignment) => (
                            <TableRow key={assignment.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="center" component="th" scope="row">{assignment.id}</TableCell>
                                <TableCell align="center">{assignment.formName}</TableCell>
                                <TableCell align="center">{assignment.assigned_name}</TableCell>
                                <TableCell align="center">{assignment.status}</TableCell>
                                <TableCell align="center">{convert(assignment?.assigned_at)}</TableCell>
                                <TableCell align="center" style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                    <Tooltip title="View" arrow>
                                        <IconButton
                                            onClick={() => {
                                                navigate('/dashboard/assignment/' + assignment.id);
                                            }}
                                            style={{ borderRadius: '7px', border: '1px solid gray', display: 'flex', justifyContent: 'center', textAlign: 'center', gap: '3px' }}
                                        >
                                            <VisibilityIcon style={{ width: '25px', height: '18px' }} /><p style={{ fontSize: '13px' }}>View</p>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete" arrow>
                                        <IconButton onClick={() => { handleClickOpen(assignment) }} style={{ borderRadius: '7px', border: '1px solid gray', background: '#4270b7', display: 'flex', justifyContent: 'center', textAlign: 'center', gap: '2px' }}>
                                            <DeleteIcon style={{ color: "white", width: '25px', height: '18px' }} /><p style={{ fontSize: '13px', color: 'white' }}>Delete</p>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={() => handleClose('no')} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Please confirm delete?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this assignment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose('no')}>No</Button>
                    <Button onClick={() => handleClose('yes')} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};


const FvName = (props) => {
    const [name, setName] = useState();
    const getFvName = async () => {
        const snapshot = await getDoc(doc(database, "field_verifier", props.uid));
        setName(snapshot.data().name);
    }
    useEffect(() => {
        getFvName();
    });
    return (<div>
        {name}
    </div>)
}

export default AssignmentsPage;